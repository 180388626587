import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,12];

export const dictionary = {
		"/": [~14],
		"/about-dubclub": [15],
		"/auth/complete/account": [16,[2]],
		"/auth/complete/social": [~17,[2]],
		"/auth/login": [18,[2]],
		"/auth/verify": [19,[2]],
		"/capper/affiliates/links": [20,[3]],
		"/capper/affiliates/members": [21,[3]],
		"/capper/affiliates/sales": [22,[3]],
		"/capper/dashboard": [23],
		"/capper/discord/custom-bot/manage": [24,[4]],
		"/capper/discord/custom-bot/setup": [25,[4]],
		"/capper/discord/manage": [~26],
		"/capper/discord/setup": [~27],
		"/capper/manage-prices": [~28,[5],[6]],
		"/capper/products": [~29],
		"/capper/profile/[name_or_price]": [~30],
		"/capper/referral": [31,[7]],
		"/capper/stream/[name]": [~32,[8]],
		"/footer": [33],
		"/plays/[textpickKey]": [~34],
		"/plays/[textpickKey]/[viewerKey]": [~35],
		"/product/new": [36],
		"/publish": [37,[9]],
		"/publish/play/[capperKey]": [~38,[9]],
		"/publish/sales": [~39,[9]],
		"/r/capper": [40],
		"/r/connected-accounts": [~41],
		"/r/discover-cappers": [42,[10]],
		"/r/discover-cappers/(discovery)/cappers": [43,[10,11]],
		"/r/discover-cappers/(discovery)/leagues": [44,[10,11]],
		"/r/discover-cappers/(discovery)/plays": [45,[10,11]],
		"/r/discover-cappers/(discovery)/search": [46,[10,11]],
		"/r/discover-cappers/(discovery)/sports": [47,[10,11]],
		"/samples/[sampleKey]": [~48],
		"/samples/[sampleKey]/[viewerKey]": [~49],
		"/t/[productKey]": [50,[12]],
		"/t/[productKey]/edit": [51],
		"/t/[productKey]/faqs": [52,[12]],
		"/t/[productKey]/notifications": [53,[12]],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~54,[13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';